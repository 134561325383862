export const JWT_TOKEN = 'JWT_TOKEN';
export const FCM_TOKEN = 'FCM_TOKEN';
export const USER_DETAIL = 'USER_DETAIL';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const COMMON_CONTENT = 'COMMON_CONTENT';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const SPA_LOGIN_FLOW = 'SPA_LOGIN_FLOW';
export const ACTIVE_SPA_HOME = 'ACTIVE_SPA_HOME';
export const CUSTOMIZING_DETAIL = 'CUSTOMIZING_DETAIL';
export const BOOK_MASSAGE = 'BOOK_MASSAGE';
export const GIFT_DETAIL = 'GIFT_DETAIL';