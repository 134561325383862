// Import StaticImage
import blueLogo from '../Assets/blueLogo.png';
import whiteLogo from '../Assets/whiteLogo.png';
import googleIcon from '../Assets/googleIcon.png';
import appleIcon from '../Assets/appleIcon.png';
import suspenseMedia from '../Assets/suspenseMedia.png'
import addMedia from '../Assets/add.png';
import profileIcon from '../Assets/profileIcon.png';
import deleteIcon from '../Assets/deleteIconWithColor.png';
import deactivateIcon from '../Assets/DeActivateIModalImage.png';
import successIcon from '../Assets/successModalIcon.png';
import bookingIcon from '../Assets/bookingMedia.png';
import radioIcon from '../Assets/checkMedia.png';
import pinMedia from '../Assets/pin.png'
import editIcon from "../Assets/edit.png"
import deleteImg from "../Assets/delete.png"
import NoProfilePic from "../Assets/NoProfilePic.png";
import emptyStar from '../Assets/emptyStar.png';
import filledStar from '../Assets/fillStar.png';
import dummyGiftCard from '../Assets/giftCard.jpg';

/* Flags */
import indiaFlag from '../Assets/flags/india.png';
import chineseFlag from '../Assets/flags/china.png';
import mandarinFlag from '../Assets/flags/mandarian.png';
import engFlag from '../Assets/flags/eng.png';
import spainFlag from '../Assets/flags/spain.png';
import frenchFlag from '../Assets/flags/fr.png';
import polishFlag from '../Assets/flags/polish.png';
import northKoreanFlag from '../Assets/flags/northKorean.png';
import japanFlag from '../Assets/flags/japan.png';
import vietnamFlag from '../Assets/flags/vietnam.png';
import tagalogFlag from '../Assets/flags/tagalog.png';
import bannerMedia from '../Assets/spaImages.png';
import profileMedia from '../Assets/profileIcon.png';
import bodyBack from '../Assets/planeBodyBack.png';
import bodyFront from '../Assets/planBodyFront.png';
import crossIcon from '../Assets/cross.png';
import greenIcon from '../Assets/moderate.png';
import mild_img from '../Assets/mild_img.png';
import mod_img from '../Assets/mod_img.png';
import red_img from '../Assets/red_img.png';
import orangeIcon from '../Assets/orange.png';
import redIcon from '../Assets/veryStrong.png';
import cancel from '../Assets/cancelIconRed.png';
import massageImg from "../Assets/dummy.png"

/* React Icons */
import { CgProfile } from "react-icons/cg";
import { FaCirclePause, FaCirclePlay, FaGift, FaHandsHolding } from "react-icons/fa6";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaRegClock, FaStar, FaUpload, FaUserCircle } from 'react-icons/fa';
import { BsCalendar2WeekFill, BsFillInfoCircleFill, BsInfoCircleFill, BsThreeDotsVertical } from "react-icons/bs";
import { MdEmail, MdKeyboardArrowRight, MdDashboard, MdOutlineWidgets } from 'react-icons/md';
import { IoIosArrowDown, IoMdAdd, IoMdAddCircle, IoMdCall, IoMdNotifications, IoMdSend } from "react-icons/io";
import { IoCloseCircleSharp, IoChevronBack, IoLocationSharp, IoHome } from "react-icons/io5";


/* React Icon Style */
import { blackColor, blueThemeColor, reactIconStyle, starColor, whiteColor } from './const';

// Export StaticImage
export const StaticImage = {
    logo: {
        blueLogo,
        whiteLogo,
    },
    flags: {
        engFlag,
        indiaFlag,
        japanFlag,
        spainFlag,
        frenchFlag,
        polishFlag,
        chineseFlag,
        vietnamFlag,
        tagalogFlag,
        mandarinFlag,
        northKoreanFlag,
    },
    socialIcon: {
        googleIcon,
        appleIcon,
    },
    banner: {
        bannerMedia
    },
    profile: {
        bookingIcon,
        radioIcon
    },
    rate: {
        emptyStar,
        filledStar
    },
    suspenseMedia,
    profileMedia,
    addMedia,
    dummyGiftCard,
    bodyFront,
    bodyBack,
    profileIcon,
    deleteIcon,
    deactivateIcon,
    crossIcon,
    mod_img,
    red_img,
    greenIcon,
    mild_img,
    orangeIcon,
    redIcon,
    pinIcon: pinMedia,
    successIcon,
    cancel,
    massageImg,
    editIcon,
    NoProfilePic,
    deleteImg
};

export const ReactIcons = {
    HomeIcon: ({ style }) => <IoHome />,
    Widgets: ({ style }) => <MdOutlineWidgets />,
    giftIcon: ({ style }) => <FaGift style={{...style}} />,
    sendIcon: ({ style }) => <IoMdSend style={reactIconStyle} />,
    callIcon: ({ style }) => <IoMdCall style={reactIconStyle} />,
    emailIcon: ({ style }) => <MdEmail style={reactIconStyle} />,
    clockIcon: ({ style }) => <FaRegClock style={reactIconStyle} />,
    userIcon: ({ style }) => <FaUserCircle style={reactIconStyle} />,
    profileIcon: ({ style }) => <CgProfile style={{ fontSize: 20 }} />,
    dashboardIcon: ({ style }) => <MdDashboard style={{ fontSize: 20 }} />,
    clockWhiteIcon: ({ style }) => <FaRegClock style={{ fontSize: 20 }} />,
    locationIcon: ({ style }) => <IoLocationSharp style={reactIconStyle} />,
    arrowDownIcon: ({ style }) => <IoIosArrowDown style={reactIconStyle} />,
    infoIcon: ({ style }) => <BsFillInfoCircleFill style={reactIconStyle} />,
    serviceIcon: ({ style }) => <FaHandsHolding style={{ fontSize: 20 }} />,
    calendarIcon: ({ style }) => <BsCalendar2WeekFill style={reactIconStyle} />,
    infoCircleFillIcon: ({ style }) => <BsInfoCircleFill style={reactIconStyle} />,
    starIcon: ({ style }) => <FaStar style={{ fontSize: 15, color: starColor }} />,
    addIcon: ({ style }) => <IoMdAdd style={{ fontSize: 35, color: whiteColor }} />,
    appointmentIcon: ({ style }) => <BsCalendar2WeekFill style={{ fontSize: 20 }} />,
    uploadIcon: ({ style }) => <FaUpload style={{ ...reactIconStyle, fontSize: 25 }} />,
    playIcon: ({ style }) => <FaCirclePlay style={{ fontSize: 60, color: whiteColor }} />,
    backIcon: ({ style }) => <IoChevronBack style={{ fontSize: 20, color: blackColor }} />,
    pauseIcon: ({ style }) => <FaCirclePause style={{ fontSize: 60, color: whiteColor }} />,
    addReactIcon: ({ style }) => <IoMdAddCircle style={{fontSize:32, color:blueThemeColor}} />,
    backWhiteIcon: ({ style }) => <IoChevronBack style={{ fontSize: 22, color: whiteColor }} />,
    editIcon: ({ style }) => <BiSolidEditAlt style={{ fontSize: 20, color: blueThemeColor }} />,
    closeIcon: ({ style }) => <IoCloseCircleSharp style={{ fontSize: 30, color: whiteColor }} />,
    moreIcon: ({ style }) => <BsThreeDotsVertical style={{ fontSize: 25, color: whiteColor }} />,
    infoWhiteIcon: ({ style }) => <BsFillInfoCircleFill style={{fontSize:18, color:whiteColor}} />,
    arrowRightIcon: ({ style }) => <MdKeyboardArrowRight style={{ ...reactIconStyle, ...style }} />,
    notifyIcon: ({ style }) => <IoMdNotifications style={{ fontSize: 40, color: blueThemeColor }} />,
}