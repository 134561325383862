
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCWAuCV23YUDIwDcsrTo4J3xkR9vOB4G7Q",
    authDomain: "mywaymassage-381d3.firebaseapp.com",
    projectId: "mywaymassage-381d3",
    storageBucket: "mywaymassage-381d3.appspot.com",
    messagingSenderId: "804376590670",
    appId: "1:804376590670:web:220b1f375b8b380abcc61f",
    measurementId: "G-D5KJX3V4MF"
};

const app = initializeApp(firebaseConfig);

let messaging = null
if (navigator.vendor !== 'Apple Computer, Inc.') {
    messaging = new getMessaging(app);
} else {
    console.log("Running on Safari")
}

/* RESPONSOBLE FOR FIRING NOTIFICATION */
export const onMessageListener = (navRec) => {
    new Promise((resolve, reject) => {
        onMessage(messaging, (payload) => {
            navRec(payload);
            resolve(payload);
        });
    });
}

export default messaging;

export const auth = getAuth(app);