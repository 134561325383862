import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root')); root.render(<App />);

// Register the service worker
serviceWorkerRegistration.register({
      onUpdate: registration => {
            alert('New content is available; please refresh.');
            window.location.reload();
      }
});