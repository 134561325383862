import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector).use(Backend).use(initReactI18next).init({
  debug: !true, //  if set to true then it will print all i18n detail in console
  fallbackLng: 'en',
  returnObjects: true,
  fallbackToDefaultNS: true, // Whether to fallback to the default namespace if translation is not found in the specified namespace
  keySeparator: '.', // Separator for nested keys in translation keys
  partialBundledLanguages: false // Whether to use partial bundled languages
})