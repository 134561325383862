import React from 'react';
import { Image } from 'antd';
import { StaticImage } from './images';

const SuspenseLoader = () => {
    return (
        <div style={{ height: '100vh', display: 'grid', placeItems: 'center', background: `url(${StaticImage.suspenseMedia})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', objectFit: 'fill' }}>
            <div className='text-center'>
                <Image preview={false} src={StaticImage.logo.blueLogo} height={250} width={250} />
            </div>
        </div>
    )
}

export default SuspenseLoader